<template>
	<div class="home">
		<v-card>
			<v-container fluid pt-0 mt-0>
				<v-row class="blue-grey darken-4">
                    <Breadcrumbs/>
                </v-row>
				<v-row><v-col cols="12"></v-col></v-row>
                
                <!-- <v-row>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-text-field label="Email" v-model="email" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-text-field label="Password" v-model="password" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-text-field label="Username" v-model="username" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-text-field label="First Name" v-model="firstname" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-text-field label="Last Name" v-model="firstname" hide-details="auto"></v-text-field>
                    </v-col>
                </v-row>
                -->
                <v-row>
                    <v-col cols="4">  
                        <v-btn @click.stop="triggerDialog(1)" :loading="btnLoading" color="primary">ADD</v-btn>
                    </v-col>
                </v-row>
                <v-dialog v-model="dialog.visible" max-width="1000">
                    <v-card>
                        <v-card-title class="headline">
                            {{ dialog.title }}
                        </v-card-title>

                        <v-card-actions>
                            <v-row>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="6">
                                    <v-text-field label="Email" :readonly="dialog.type == 2" v-model="dialog.email" hide-details="auto"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="6">
                                    <v-text-field label="Password" v-model="dialog.password" hide-details="auto"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="6">
                                    <v-text-field label="Username" v-model="dialog.username" hide-details="auto"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="6">
                                    <v-text-field label="First Name" v-model="dialog.firstname" hide-details="auto"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="6">
                                    <v-text-field label="Last Name" v-model="dialog.lastname" hide-details="auto"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <p>Permission</p>
                                    <vue-json-editor id="jsoneditor" v-model="dialog.permission"
                                        :mode="mode" :modes="modes" :showBtns="false" :width="'100%'">
                                    </vue-json-editor>
                                </v-col>
                            </v-row>
                        </v-card-actions>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialog.visible = false">Cancel</v-btn>
                            <v-btn color="green darken-1" text @click="dialog.visible = false">
                                <span v-if="dialog.type == 1">Save</span>
                                <span v-if="dialog.type == 2">Update</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-row>
                    <v-col cols="12">
                        <v-data-table dense :headers="headers" :items="listUser" class="elevation-1" :items-per-page="itemsPerPage">
                            
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" @click="triggerDialog(2, item)">
                                    mdi-pencil
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import * as apiUser from "@components/api/pk/user";
import Breadcrumbs from "@components/Breadcrumbs";
import vueJsonEditor from "vue-json-editor";

export default {
    components: {Breadcrumbs, vueJsonEditor},
    data() {
        return {
            btnLoading: false,
            listUser: [],
            itemsPerPage: 10,
            dialog: {
                type: 0,
                visible: false,
                title: "",
                email: "",
                password: "",
                username: "",
                firstname: "",
                lastname: "",
                permission: []
            },
			mode: 'code',
			modes: ['code', 'tree'],
            headers:[
                {text: "ID", value: "id"},
                {text: "Last Login", value: "last_login"},
                {text: "Username", value: "username"},
                {text: "First", value: "first_name"},
                {text: "Last", value: "last_name"},
                {text: "Email", value: "email"},
                {text: "Active", value: "is_active"},
                {text: "Date Join", value: "date_joined"},
                // {text: "Permission", value: "permission"},
                // {text: "AT", value: "access_token"},
                // {text: "RT", value: "refresh_token"},
                {text: "Created At", value: "created_at"},
                {text: "Updated At", value: "updated_at"},
                { text: 'Actions', value: 'actions', sortable: false },
            ]
        }
    },
    methods: {
        onCmCodeChange(newCode) {
            this.dialog.permission = newCode
        },
        triggerDialog(type, data=null) {
            this.dialog.type = type;
            this.dialog.visible = true;
            
            if (type == 1) {
                this.dialog.title = "Add New User";
            }
            
            if (type == 2) {
                this.dialog.title = "Update Existing User";
                this.dialog.email = data.email;
                this.dialog.password = data.password;
                this.dialog.username = data.username;
                this.dialog.firstname = data.first_name;
                this.dialog.lastname = data.last_name;
                this.dialog.permission = JSON.parse(data.permission);
            }
        },
        userHandler() {
            let self = this;

            return {
                load() {
                    apiUser.fetch().then(res => {
                        self.listUser = res.data;
                        console.log(self.listUser);
                    })
                },
                create() {

                }
            }
        }
    },
    mounted() {
        this.userHandler().load();
    }
}
</script>

<style scoped>
.jsoneditor-outer {
    height: 500px !important;
}
</style>